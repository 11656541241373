<script lang="ts" setup>
import { useComingSoonFetch } from '~/composables/useComingSoonApi';
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";
import {useForm, useField} from 'vee-validate';

definePageMeta({
    layout: false
})

useHead(
    {
      title: 'Coming Soon'
    }
)

const {subscribe, userRegister, isError, isLoading} = useComingSoonFetch();

const showSuccesDialog = ref(false)
const showAlreadyHasDialog = ref(false)
const email = ref('')

async function onSubmit() {
    await subscribe(email.value);
    
    if(userRegister.value) showSuccesDialog.value = true
    if(!userRegister.value) showAlreadyHasDialog.value = true
};
</script>

<template>
    <div>
        <div class="min-h-screen bg-[#0C3B2E] flex items-center justify-center px-8">
            <div class="content max-w-[650px] flex flex-col items-center">
                <img src="/img/coming_soon_logo.svg" alt="Yourservice">
                <p class="font-bold font-rubik text-3xl md:text-5xl text-white text-center mt-[60px] leading-tight">Find a <span
                        class="text-accent">reliable person</span> for any task</p>
                <form @submit.prevent="onSubmit" class="grid md:grid-flow-col gap-5 mt-[70px] w-full">
                    <UiInput v-model="email" required type="email" placeholder="Get notified when we launch" class="rounded-full px-6 font-normal h-12 md:min-w-[450px]" />
        
                    <UiButton :disabled="!email && isLoading" type="submit" variant="primary" class="rounded-full px-12 h-12">
                        <Icon v-if="isLoading" name="svg-spinners:ring-resize" size="24px" class="mr-2" />
                        Subscribe
                    </UiButton>
                </form>
            </div>
        </div>

        <UiDialog v-model:open="showSuccesDialog">
            <UiDialogContent class="p-0 gap-0">
                <UiDialogHeader class="p-5">
                    <div class="flex items-center justify-center h-10 w-10 rounded-[10px] bg-primary-50 mx-auto">
                        <Icon name="ri:checkbox-circle-fill" size="24px" class="text-primary-500" />
                    </div>
                    <p class="font-medium mt-4 text-center">We've added you to the waiting list!</p>
                    <p class="text-sm text-gray-500 mt-1">As soon as we launch we will send you a notification to the
                        email you provided</p>
                </UiDialogHeader>
                <UiDialogFooter class="border-t border-gray-200 px-5 py-4">
                    <UiButton @click.prevent="showSuccesDialog = false" type="primary"
                        class="w-full bg-primaryblue-500 hover:bg-primaryblue-700 text-white text-sm">Thanks</UiButton>
                </UiDialogFooter>
            </UiDialogContent>
        </UiDialog>

        <UiDialog v-model:open="showAlreadyHasDialog">
            <UiDialogContent class="p-0 gap-0">
                <UiDialogHeader class="p-5">
                    <div class="flex items-center justify-center h-10 w-10 rounded-[10px] bg-primaryblue-50 mx-auto">
                        <Icon name="ri:information-fill" size="24px" class="text-primaryblue-500" />
                    </div>
                    <p class="font-medium mt-4 text-center">This email is already on the waiting list !</p>
                    <p class="text-sm text-gray-500 mt-1">As soon as we launch we will send you a notification to the
                        email you provided</p>
                </UiDialogHeader>
                <UiDialogFooter class="border-t border-gray-200 px-5 py-4">
                    <UiButton @click.prevent="showAlreadyHasDialog = false" type="primary"
                        class="w-full bg-primaryblue-500 hover:bg-primaryblue-700 text-white text-sm">Thanks</UiButton>
                </UiDialogFooter>
            </UiDialogContent>
        </UiDialog>
    </div>
</template>