import {ref} from "vue";
import {useRuntimeConfig} from "#app";


interface ResponseType {
    user_exists: boolean
}

export function useComingSoonFetch() {
    const userRegister = ref(false)
    const isLoading = ref(false)
    const isError = ref(false)
    const config = useRuntimeConfig();
    const subscribe = async (email: string) => {
        isLoading.value = true
        try {
            await $fetch<ResponseType>(`${config.public.baseURL}/user/waiting-list/`, {
                method: 'POST',
                body: JSON.stringify({
                    "email": email,
                })
            })
            userRegister.value = true;
            isError.value = false;
        } catch (e) {           
            userRegister.value = false;
            isError.value = true;
        } finally {
            isLoading.value = false
        }
    }
    return {subscribe, userRegister, isError, isLoading};
}
